#desktop {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 44px);
  background: #dacdbc url("/static/bg/mth-logo-bg.jpg") no-repeat;
  background-position: center;
  background-size: contain;
}

#icons {
  position: relative;
  z-index: 1;
  padding-top: 30px;
  height: 100%;
}

.desktop-icon {
  @include li;
  &:not(:first-child) {
    margin-top: 40px;
  }
  &[tabindex] {
    &:active,
    &:focus {
      span {
        background: $mauve;
        color: white;
        border: 1px dotted $oak;
        @media (min-width: 500px) {
          border-width: 1.5px;
        }
      }
    }
  }
}
