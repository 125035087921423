@font-face {
    font-family: "MS Sans Serif";
    src: url("../assets/fonts/ms-sans-serif.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "MS Sans Serif";
    src: url("../assets/fonts/ms-sans-serif-bold.woff2") format("woff2");
    font-weight: bold;
    font-display: swap;
  }