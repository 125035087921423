// scrollbars
// https://codepen.io/louh/pen/oZJQvm
$sc-wd: 24px;

::-webkit-scrollbar {
  width: $sc-wd;
  height: $sc-wd;
}

::-webkit-scrollbar-track {
  @include ch(lighten($g2, 15%));
}

::-webkit-scrollbar-thumb {
  @include sh(3px);
  width: $sc-wd;
  height: $sc-wd;
  background-color: $g2;
  z-index: 1;
}

::-webkit-scrollbar-corner {
  background-color: $g2;
}

::-webkit-resizer {
  width: $sc-wd;
  height: $sc-wd;
  background-color: $g2;
  background-color: transparent;
  background-image: url($re);
  background-position: bottom right;
  background-repeat: no-repeat;
  image-rendering: pixelated;
}

::-webkit-scrollbar-button {
  @include sh(3px);
  display: block;
  width: $sc-wd;
  height: $sc-wd;
  background-color: $g2;
  image-rendering: pixelated;
  background-size: $sc-wd * 0.75 $sc-wd * 0.75;
  background-repeat: no-repeat;
  background-position: center center;
  &:active {
    background-position: 2px 2px;
  }
  &:horizontal:decrement {
    background-image: url($lt);
  }
  &:horizontal:increment {
    background-image: url($rt);
  }
  &:vertical:decrement {
    background-image: url($up);
  }
  &:vertical:increment {
    background-image: url($dn);
  }
  &:horizontal:increment:start {
    display: none;
  }
  &:horizontal:decrement:end {
    display: none;
  }
  &:vertical:increment:start {
    display: none;
  }
  &:vertical:decrement:end {
    display: none;
  }
  &:active {
    border: 1.5px solid $g3;
    box-shadow: none;
  }
}
