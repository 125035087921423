// Theme based on:
// https://codepen.io/gabriellewee/pen/MWqRZzp
// https://codepen.io/louh/pen/oZJQvm

* {
  box-sizing: border-box;
}

body,
html {
  overflow: hidden;
  background-color: #dacdbc;
}

// $url: "https://assets.codepen.io/345377/";
$url: "../assets/icons/";

@import "config.scss";
@import "assets.scss";
@import "fonts.scss";
@import "mixins.scss";
@import "icons.scss";
@import "buttons.scss";
@import "desktop.scss";
@import "windows.scss";
@import "../../resources/codepen-windows/styles.scss";
@import "scrollbars.scss";
