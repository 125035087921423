


// base styles
*,
*:before,
*:after {
  cursor: default;
  box-sizing: border-box;
  image-rendering: pixelated;
  font-family: "MS Sans Serif", Tahoma, Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 1px;
  // cursor: default;
  cursor: url("#{$url}cursor.png") 0 0, pointer;
  // cursor: url("#{$url}cursor.svg") 0 0, pointer;
  cursor: -webkit-image-set(url("#{$url}cursor.png") 1x, url("#{$url}cursor@2x.png") 2x) 0 0, pointer;
}

*:not(textarea, input) {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:focus {
  outline: none;
}

::selection {
  background: none;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  background-color: $bg;
  color: $bl;
  line-height: 1;
  overflow: hidden;
}

textarea {
  display: block;
  border: 0;
  padding: 3px;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: scroll;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  &::selection {
    background-color: $slate;
    -webkit-tap-highlight-color: $slate;
    color: white;
  }
}

.textarea-container {
  @include sh(3px, inside);
}

[hidden] {
  appearance: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

// menu and label styles
menu,
ul:not(.information ul),
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.underline {
  > li {
    > a,
    > label {
      &:not(.no-line) {
        &,
        > span,
        .line {
          @include fl;
        }
        > span span:not(.line) {
          &:first-letter {
            text-decoration: none;
          }
        }
        .line {
          display: inline-block;
        }
      }
    }
  }
}
label,
a {
  &:before,
  &:after {
    content: "";
    background-position: center center;
    background-size: contain;
  }
}
label[tabindex] {
  span {
    padding: 1px 2px;
    span {
      padding: 0;
    }
  }
}
[class*="label"]:not(.folders label):not(.label-nested) {
  &:after {
    @include ch($mauve, 1.5px, transparent);
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    mask-position: center;
    mask-size: cover;
  }
  &[tabindex] {
    &:active,
    &:focus {
      &:after {
        opacity: 1;
      }
    }
  }
}
.label-nested {
  &:after {
    position: absolute;
    right: 6px;
    width: 16px;
    height: 16px;
    background-image: url($rt);
    background-size: 16px 16px;
  }
  &:hover:after {
    filter: invert(1) brightness(200%);
  }
}
li:hover > .label-nested {
  &:after {
    filter: invert(1) brightness(200%);
  }
}







#window-taskbar {
  left: 3px;
  bottom: 48px;
  > .window-inside {
    resize: none;
    width: 510px;
    height: 600px;
    > .window-content {
      padding: 12px 9px 10px;
      height: calc(100% - 28px - 3px - 36px - 9px);
    }
  }
  .window-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 9px;
  }
  .tab-container {
    height: 100%;
  }
  .tab-group {
    display: flex;
  }
  .tab {
    display: block;
    position: relative;
    z-index: 0;
    margin-top: 3px;
    line-height: 32px;
    padding: 0 12px;
    background-color: $g2;
    &:first-child {
      transform: translateX(3px);
    }
    &:not(:first-child) {
      margin-left: -3px;
      transform: translateX(-3px);
    }
    &:before,
    &:after,
    span:before,
    span:after {
      content: "";
      display: block;
      position: absolute;
    }
    &:before {
      width: calc(100% - 6px);
      height: 100%;
      top: 0;
      left: 3px;
      border-top: 1.5px solid $wh;
    }
    &:after {
      width: 100%;
      height: calc(100% - 3px);
      bottom: 0;
      left: 0;
      border-left: 1.5px solid $wh;
      border-right: 1.5px solid $bl;
      box-shadow: inset -1.5px 0 0 0 $g3;
    }
    span {
      &:before,
      &:after {
        top: 1.5px;
        width: 1.5px;
        height: 1.5px;
      }
      &:before {
        left: 1.5px;
        background-color: $wh;
      }
      &:after {
        right: 1.5px;
        background-color: $bl;
      }
    }
  }
  $taskbar-tabs: taskbar-options, start-menu-programs;
  @each $tab in $taskbar-tabs {
    ##{$tab}:checked ~ .window-inside {
      .tab-#{$tab} {
        z-index: 12;
        margin-top: 0;
        transform: none;
      }
      .tabpanel.#{$tab} {
        z-index: 12;
      }
    }
  }
  .tabpanel-group {
    position: relative;
    z-index: 0;
    margin-top: -1.5px;
    height: calc(100% - 32px + 1.5px);
  }
  .tabpanel {
    @include sh(3px, button);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $g2;
  }

  .taskbar-options {
    padding: 32px;
  }
  .preview-container {
    @include sh(3px, inset);
    display: grid;
    place-items: center;
    width: 100%;
    height: 200px;
    background: $bg;
  }
  .preview {
    position: relative;
    overflow: hidden;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    * {
      pointer-events: none;
    }
    .window {
      opacity: 1;
      visibility: visible;
      z-index: 2;
      bottom: 22px;
      left: calc(100% - 96px);
    }
    .window-inside {
      width: 300px;
      height: 200px;
    }
    .window-content {
      height: 100%;
    }
    .list-container {
      height: 100%;
    }
    .list {
      display: block;
      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 116px;
        height: 98px;
        white-space: unset;
        text-align: center;
        line-height: 1.3;
        &:before {
          margin-bottom: 6px;
          margin-right: 0;
          width: 48px;
          height: 48px;
        }
      }
    }
    .start-menu,
    .start-menu-outline {
      bottom: 1px;
      left: 4px;
      width: calc(100% - 8px);
    }
    .start-menu-outline {
      visibility: hidden;
      position: absolute;
      height: 44px;
      border: 1.5px dotted $mint;
      border-bottom: 0;
      z-index: 10;
    }
    .start-menu {
      @include sh(3px);
      padding: 0 2px 0 1px;
      justify-content: space-between;
      .label-start {
        @include sh(3px, button-reverse);
      }
      .menu-container {
        visibility: visible;
        z-index: 2;
        left: 0;
        bottom: 44px;
      }
      .time {
        display: flex;
        align-items: center;
        width: auto;
        padding: 0 10px;
        &:before {
          content: "";
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-size: contain;
        }
      }
    }
  }
  .options {
    width: 100%;
    label {
      display: flex;
      align-items: center;
      margin-top: 32px;
      position: relative;
      &:before,
      &:after {
        display: block;
        width: 20px;
        height: 20px;
      }
      &:before {
        @include sh(3px, inside);
        margin-right: 8px;
        background-color: white;
      }
      &:after {
        position: absolute;
        left: 0;
        visibility: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($y);
        background-size: 10px 10px;
      }
      > span {
        border: 1.5px dotted transparent;
        line-height: 1;
        height: 20px;
      }
      &:focus {
        > span {
          border: 1.5px dotted;
        }
      }
    }
  }

  .start-menu-programs {
    padding: 0 20px 20px;
    form {
      display: block;
      width: 100%;
    }
    fieldset {
      @include sh(3px, fieldset);
      display: block;
      width: 100%;
      margin-top: 20px;
      border: 0;
      padding: 32px 32px 20px;
    }
    legend {
      background-color: $g2;
      margin-left: -22px;
      transform: translateY(2px);
      padding: 0 5px;
    }
    p {
      display: flex;
      align-items: flex-start;
      width: calc(100% - 20px);
      margin: 0;
      line-height: 1.3;
      &:before {
        content: "";
        display: block;
        min-width: 48px;
        width: 48px;
        height: 48px;
        background-size: contain;
        margin-right: 24px;
      }
    }
    .menu-options {
      display: flex;
      justify-content: flex-end;
      li {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
    .customize-start-menu {
      .menu-options {
        margin-top: 48px;
      }
    }
    .documents-menu {
      .menu-options {
        margin-top: 20px;
      }
    }
  }
}
$options: always-on-top, auto-hide, show-small-icons, show-clock;
@each $option in $options {
  #option-#{$option}:checked ~ .window-inside {
    label[for="option-#{$option}"] {
      &:after {
        visibility: visible;
      }
    }
  }
}
#option-always-on-top:not(:checked) ~ .window-inside .preview .window {
  z-index: 11;
}
#global-option-always-on-top:not(:checked) ~ main {
  #start-menu {
    z-index: unset;
    .menu-container {
      z-index: 1000000;
    }
  }
}
#start:checked ~ #global-option-always-on-top:not(:checked) ~ main {
  #start-menu {
    z-index: 1000000;
    .menu-container {
      z-index: unset;
    }
  }
}
#option-auto-hide:checked ~ .window-inside .preview {
  .start-menu-outline {
    visibility: visible;
  }
  .start-menu {
    @include sh(3px, hide);
    height: 10px;
    > * {
      visibility: hidden;
    }
  }
}
#global-option-auto-hide:checked ~ main:not(#start:checked ~ main) {
  .start-menu {
    transform: translateY(100%);
    &:hover {
      transform: none;
    }
  }
  #start-menu-outline {
    visibility: visible;
    &:hover {
      + .start-menu {
        transform: none;
      }
    }
  }
}
#option-show-small-icons:checked ~ .window-inside .preview,
#global-option-show-small-icons:checked ~ main #start-menu {
  $small-icons: folder-programs, folder-documents, settings, find, help, run,
    shut-down;
  @each $icon in $small-icons {
    .label-#{$icon} {
      &:before {
        // background-image: url("#{$url}#{$icon}-small.png");
      }
    }
  }
  .menu-container:before {
    display: none;
  }
  .main-menu {
    > li {
      &:last-child {
        padding-top: 8px;
      }
      > a,
      > label {
        height: 34px;
        width: 175px;
        &:before {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
#option-show-clock:not(:checked) ~ .window-inside .preview {
  .time:before {
    margin-right: 0;
  }
  time {
    display: none;
  }
}
#global-option-show-clock:not(:checked) ~ main #start-menu .time {
  display: none;
}

#window-printers {
  .window-inside {
    width: 480px;
    height: 390px;
  }
}
#window-control-panel {
  .window-inside {
    width: 520px;
    height: 480px;
  }
}
#window-explorer {
  .window-nav {
    h3 > span {
      position: relative;
      height: 28px;
      span {
        visibility: hidden;
        top: 0;
        position: absolute;
      }
    }
  }
  .window-content {
    display: flex;
    flex-wrap: wrap;
    h4,
    h5 {
      @include nb;
      @include sh(3px, inset);
      margin: 0;
      padding: 0 6px;
      font-weight: normal;
      line-height: 28px;
    }
    .folders {
      width: 250px;
      .long {
        min-width: 180px;
      }
      label:not(.label-toggle) {
        &:after {
          display: block;
          position: absolute;
          z-index: 1;
          left: -17px;
          width: 17px;
          border-top: 1px dotted $g3;
          @media (min-width: 500px) {
            left: -14px;
            width: 14px;
            border-top-width: 1.5px;
          }
        }
      }
    }
    .folders-content {
      position: relative;
      margin-left: 4px;
      width: calc(100% - 254px);
    }
    .content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      .list {
        li {
          min-width: 210px;
        }
      }
    }
  }
}

$windows: welcome, taskbar, printers, control-panel, computer, notepad, explorer;
@each $window in $windows {
  ##{$window} {
    main {
      #window-#{$window} {
        will-change: transform;
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }
      .tab-#{$window} {
        display: flex;
      }
      .menu .label-#{$window} {
        pointer-events: none;
      }
    }
    // &.active ~ ##{$window}-minimize:not(:checked) ~ main,
    &.active.no-tab ~ main {
      #window-#{$window} {
        z-index: 10;
        .window-nav {
          background-color: $slate;
        }
      }
      .window[data-window]:not(#window-#{$window}) {
        z-index: 2;
      }
      .tab-#{$window} label {
        @include ta;
      }
      .activate:not(.tab-#{$window} .activate) {
        z-index: 1;
      }
      .tab label:not(.tab-#{$window} label) {
        z-index: 0;
      }
    }
  }
  ##{$window}-minimize {
    &:active ~ main #window-#{$window} .window-inside {
      .minimize {
        @include sh(3px, button-reverse);
      }
    }
    &:checked ~ main #window-#{$window} {
      transform: scale(0);
    }
  }
  ##{$window}-maximize {
    &:active ~ main #window-#{$window} .window-inside {
      .maximize {
        @include sh(3px, button-reverse);
      }
    }
    &:checked ~ main #window-#{$window} {
      left: unset !important;
      top: 0 !important;
      .window-inside {
        max-width: calc(100vw - 12px);
        width: calc(100vw - 12px) !important;
        height: calc(100vh - 44px - 12px) !important;
        .maximize {
          &:before,
          &:after {
            width: 9px;
            height: 9px;
          }
          &:before {
            visibility: visible;
            top: 3px;
            right: 6px;
          }
          &:after {
            bottom: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.menu-status-bar:checked ~ .window-inside {
  .label-check.label-status-bar:before {
    visibility: visible;
  }
  .window-footer {
    display: flex;
  }
  .above-footer {
    height: calc(100% - 26px - 3px);
  }
}
.menu-large-icons:checked ~ .window-inside {
  .label-check.label-large-icons:before {
    visibility: visible;
  }
  .window-content {
    .list:not(.folders .list) {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, 120px);
      row-gap: 24px;
      padding: 9px 0;
      li {
        label {
          white-space: unset;
          text-align: center;
          line-height: 1.3;
          height: 98px;
          &:before {
            margin-bottom: 6px;
            margin-right: 0;
          }
          &:active,
          &:focus {
            span {
              background: $mauve;
              color: white;
              border: 1px dotted $oak;
              @media (min-width: 500px) {
                border-width: 1.5px;
              }
            }
          }
        }
      }
    }
    @each $icon in $icons {
      .label-#{$icon}-small:not(.folders label) {
        @include li;
        &:before {
          // background-image: url("#{$url}#{$icon}.png");
        }
      }
    }
  }
}
.menu-small-icons:checked ~ .window-inside {
  .label-check.label-small-icons:before {
    visibility: visible;
  }
  .window-content {
    .list:not(.folders .list) {
      flex-direction: row;
      label {
        width: 210px;
      }
    }
  }
}
.menu-list-icons:checked ~ .window-inside {
  .label-check.label-list-icons:before {
    visibility: visible;
  }
  .window-content {
    .list:not(.folders list) {
      flex-direction: column;
    }
  }
}

$alerts: blue-screen, diskette-a, diskette-b;
#alerts {
  z-index: 1000001;
}
@each $alert in $alerts {
  #error-#{$alert} {
    main {
      #alert-#{$alert} {
        will-change: transform;
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }
    }
  }
}

$toggles: computer, disk, program-files, windows;
@each $toggle in $toggles {
  #toggle-#{$toggle} {
    &:checked ~ main #window-explorer {
      .toggle-#{$toggle} {
        &:after {
          visibility: hidden;
        }
        ~ menu {
          display: block;
        }
      }
    }
  }
}

$explorer-panels: desktop, computer, disk, dos, program-files, windows,
  control-panel, printers, recycle-bin;
@each $panel in $explorer-panels {
  #explorer-#{$panel} {
    &:checked ~ main #window-explorer {
      .title-explorer-#{$panel},
      .content-explorer-#{$panel},
      .explorer-number-#{$panel},
      .explorer-space-#{$panel} {
        visibility: visible;
      }
      .label-folder-small[for="explorer-#{$panel}"]:before {
        background-image: url("#{$url}folder-open-small.png");
      }
      .content-explorer-#{$panel} {
        visibility: visible;
        opacity: 1;
        z-index: unset;
      }
    }
  }
}

// start menu
#start {
  &:focus,
  &:active,
  &:checked {
    ~ main > .start-menu .label-start {
      outline: 1px dotted $bl;
      outline-offset: -6px;
      @media (min-width: 500px) {
        outline-width: 1.5px;
      }
    }
  }
  &:active,
  &:checked {
    ~ main > .start-menu .label-start {
      @include sh(3px, button-reverse);
    }
  }
  &:checked {
    ~ main > .start-menu .menu-container {
      visibility: visible;
      z-index: unset;
      .sub-menu:hover {
        visibility: visible;
        z-index: 2;
      }
      .main-menu {
        a:hover ~ .sub-menu {
          visibility: visible;
          opacity: 1;
          z-index: 2;
        }
      }
    }
  }
}
#start-menu-outline {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: $g3;
}
.start-menu {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background-color: $g2;
  box-shadow: inset 0 1.5px 0 0 $g2, inset 0 3px 0 0 $wh;
  padding-top: 3px;
  .menu-container,
  .sub-menu {
    @include bt(3px);
    position: absolute;
    padding: 4px;
  }
  .menu {
    li {
      position: relative;
      &:hover {
        > label,
        > a {
          background-color: $slate;
          color: white;
        }
      }
    }
    label,
    a {
      display: flex;
      align-items: center;
      align-content: center;
      width: 200px;
      padding-left: 8px;
      height: 32px;
      &:before {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      &:hover {
        background-color: $slate;
        color: white;
      }
      &.label-empty {
        justify-content: center;
        padding-left: 0;
        &:before,
        &:after {
          display: none;
        }
      }
      &.label-disabled,
      &.label-empty {
        &,
        &:hover {
          color: #85888c;
        }
      }
    }
  }
  .menu-container {
    visibility: hidden;
    display: flex;
    align-items: stretch;
    z-index: -1;
    bottom: 38px;
    left: 4px;
    &:before {
      content: "";
      display: block;
      background-color: desaturate(lighten($slate, 15%), 15%);
      width: 32px;
    }
    .sub-menu {
      visibility: hidden;
      z-index: -1;
      display: block;
      top: -4px;
      left: calc(100% - 4px);
      margin: 0;
    }
    .main-menu {
      > li {
        &:nth-last-child(2) {
          border-bottom: 1.5px solid $g3;
        }
        &:last-child {
          border-top: 1.5px solid $wh;
        }
        > label,
        > a {
          height: 50px;
          &:before {
            width: 48px;
            height: 48px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .label-start {
    font-weight: bold;
    margin-left: 4px;
    &:before {
      width: 24px;
      height: 21px;
      margin-right: 5px;
      background-image: url("#{$url}start.png");
      background-size: 24px 21px;
    }
  }
  .label-start,
  .tabs label {
    @include bt(3px, button);
    height: 34px;
    padding: 0 6px !important;
    background-color: $g2;
  }
  .tabs {
    display: flex;
    width: calc(100% - 79px - 100px - 6px);
    .tab {
      flex-basis: 200px;
      display: none;
      position: relative;
      overflow: hidden;
      margin-left: 4px;
    }
    .activate {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    label {
      position: relative;
      z-index: 1;
      width: 100%;
      justify-content: flex-start;
      span {
        @include nb;
      }
      &:before {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 3px;
      }
      &:active {
        @include ta;
      }
    }
  }
  .time {
    @include nb;
    @include sh(3px, inset);
    display: grid;
    place-items: center;
    height: 34px;
    width: 100px;
    margin: 0 3px;
  }
}
#start-menu {
  z-index: 1000000;
}

