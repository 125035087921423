* {
  box-sizing: border-box;
}

body, html {
  background-color: #dacdbc;
  overflow: hidden;
}

@font-face {
  font-family: MS Sans Serif;
  src: url("ms-sans-serif.601ee140.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: MS Sans Serif;
  src: url("ms-sans-serif-bold.d84063c0.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

.label-computer:before {
  background-image: url("computer.49c58c1b.png");
  background-repeat: no-repeat;
}

.label-computer:not(.folders label):not(.label-nested):after {
  mask-image: url("computer.49c58c1b.png");
}

.label-cd:before {
  background-image: url("cd.a8dc0ed0.png");
  background-repeat: no-repeat;
}

.label-cd:not(.folders label):not(.label-nested):after {
  mask-image: url("cd.a8dc0ed0.png");
}

.label-modems:before {
  background-image: url("modems.b3ebdf4d.png");
  background-repeat: no-repeat;
}

.label-modems:not(.folders label):not(.label-nested):after {
  mask-image: url("modems.b3ebdf4d.png");
}

.label-joystick:before {
  background-image: url("joystick.475924ac.png");
  background-repeat: no-repeat;
}

.label-joystick:not(.folders label):not(.label-nested):after {
  mask-image: url("joystick.475924ac.png");
}

.label-multimedia:before {
  background-image: url("multimedia.800ee850.png");
  background-repeat: no-repeat;
}

.label-multimedia:not(.folders label):not(.label-nested):after {
  mask-image: url("multimedia.800ee850.png");
}

.label-skip-awgs-x2:before {
  background-image: url("skip-awgs-x2.482fa318.png");
  background-repeat: no-repeat;
}

.label-skip-awgs-x2:not(.folders label):not(.label-nested):after {
  mask-image: url("skip-awgs-x2.482fa318.png");
}

.label-tryb-sporth:before {
  background-image: url("tryb-sporth.1125c64d.png");
  background-repeat: no-repeat;
}

.label-tryb-sporth:not(.folders label):not(.label-nested):after {
  mask-image: url("tryb-sporth.1125c64d.png");
}

.label-wordpad:before {
  background-image: url("wordpad.da5c380e.png");
  background-repeat: no-repeat;
}

.label-wordpad:not(.folders label):not(.label-nested):after {
  mask-image: url("wordpad.da5c380e.png");
}

.label-taskbar:before {
  background-image: url("taskbar.a30ca1a8.png");
  background-repeat: no-repeat;
}

.label-customize-start-menu:before {
  background-image: url("customize-start-menu.81b1c879.png");
  background-repeat: no-repeat;
}

.label-recycle-bin-folder:before {
  background-image: url("recycle-bin-folder.acc4ead9.png");
  background-repeat: no-repeat;
}

.icon {
  background-repeat: no-repeat;
  width: 40px;
  height: 32px;
  display: block;
}

.icon-instagram {
  background-image: url("instagram.a48324c5.png");
}

.icon-tiktok {
  background-image: url("tiktok.2a765c0e.png");
}

.button {
  z-index: 4;
  appearance: none;
  text-align: center;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 36px;
  padding-bottom: 1.5px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #cac6cb;
}

.button.underline span:first-letter {
  text-decoration: underline;
}

.button + .button {
  margin-left: 10px;
}

.button > span {
  border: 1px dotted #0000;
  width: 100%;
  line-height: 22px;
  display: block;
  padding: 0 !important;
}

@media (width >= 500px) {
  .button > span {
    border-width: 1.5px;
  }
}

.button[disabled] {
  color: #a099a1;
  pointer-events: none;
}

.button:not([disabled]):focus {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5px;
  display: flex;
  box-shadow: inset -3px -3px #263238, inset 1.5px 1.5px #263238, inset -4.5px -4.5px #a099a1, inset 3px 3px #fcfcfc;
}

.button:not([disabled]):active {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #263238, inset -3px -3px #a099a1, inset 3px 3px #a099a1;
}

.button:not([disabled]):focus span, .button:not([disabled]):active span {
  color: #263238;
  background: none;
  border-color: #263238;
}

#desktop {
  background: #dacdbc url("mth-logo-bg.1ad4da98.jpg") center / contain no-repeat;
  width: 100vw;
  height: calc(100vh - 44px);
  position: absolute;
  top: 0;
  left: 0;
}

#icons {
  z-index: 1;
  height: 100%;
  padding-top: 30px;
  position: relative;
}

.desktop-icon {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.desktop-icon:before, .desktop-icon:after {
  width: 48px;
  height: 48px;
}

.desktop-icon:before {
  margin-bottom: 6px;
}

.desktop-icon:after {
  left: 36px;
}

.desktop-icon span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.desktop-icon:not(:first-child) {
  margin-top: 40px;
}

.desktop-icon[tabindex]:active span, .desktop-icon[tabindex]:focus span {
  color: #fff;
  background: #b2738e;
  border: 1px dotted #d1ae87;
}

@media (width >= 500px) {
  .desktop-icon[tabindex]:active span, .desktop-icon[tabindex]:focus span {
    border-width: 1.5px;
  }
}

#windows, #alerts {
  place-items: center;
  width: 100%;
  height: calc(100% + 44px);
  padding-bottom: 44px;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.window, .alert {
  z-index: 4;
  background: #cac6cb;
  padding: 6px;
  position: absolute;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #dedcde, inset -3px -3px #a099a1, inset 3px 3px #fcfcfc;
}

.window-inside, .alert-inside {
  resize: both;
  min-width: 300px;
  max-width: calc(100vw - 36px);
  min-height: 200px;
  max-height: calc(100vh - 56px);
  overflow: hidden;
}

.window-inside::-webkit-resizer {
  display: none;
}

.alert-inside::-webkit-resizer {
  display: none;
}

.window-nav {
  background-color: #a099a1;
}

.alert-nav {
  background-color: #577783;
}

.window-nav, .alert-nav {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  padding: 0 3px;
  display: flex;
}

.window-nav:before, .window-nav:after, .alert-nav:before, .alert-nav:after {
  content: unset;
}

.window-nav h3, .alert-nav h3 {
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 69px);
  margin: 0;
  line-height: 28px;
  display: flex;
}

.window-nav h3[class*="label"]:before, .alert-nav h3[class*="label"]:before {
  content: "";
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  display: block;
}

.window-nav h3[class*="label"] span:only-child, .alert-nav h3[class*="label"] span:only-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  overflow: hidden;
}

.window-nav h3:not([class*="label"]), .alert-nav h3:not([class*="label"]) {
  padding: 0 3px;
}

.window-nav .buttons, .alert-nav .buttons {
  display: flex;
}

.window-nav .buttons label, .alert-nav .buttons label {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 20px;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  position: relative;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #cac6cb;
}

.window-nav .buttons label[disabled], .alert-nav .buttons label[disabled] {
  pointer-events: none;
}

.window-nav .buttons label[disabled]:after, .alert-nav .buttons label[disabled]:after {
  opacity: .4;
}

.window-nav .buttons label:last-child, .alert-nav .buttons label:last-child {
  margin-left: 3px;
}

.window-nav .buttons label.minimize:after, .alert-nav .buttons label.minimize:after {
  background: #263238;
  width: 10px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: 4.5px;
  left: 5px;
}

.window-nav .buttons label.maximize:before, .window-nav .buttons label.maximize:after, .alert-nav .buttons label.maximize:before, .alert-nav .buttons label.maximize:after {
  content: "";
  background-color: #cac6cb;
  border: 1.5px solid #263238;
  border-top-width: 3px;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
}

.window-nav .buttons label.maximize:before, .alert-nav .buttons label.maximize:before {
  visibility: hidden;
}

.window-nav .buttons label.close:after, .alert-nav .buttons label.close:after {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDggNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgPHBvbHlnb24gc3Ryb2tlPSJub25lIiBmaWxsPSIjMjYzMjM4IiBwb2ludHM9IjMgMyAyIDMgMiAyIDEgMiAxIDEgMCAxIDAgMCAyIDAgMiAxIDMgMSAzIDIgNSAyIDUgMSA2IDEgNiAwIDggMCA4IDEgNyAxIDcgMiA2IDIgNiAzIDUgMyA1IDQgNiA0IDYgNSA3IDUgNyA2IDggNiA4IDcgNiA3IDYgNiA1IDYgNSA1IDMgNSAzIDYgMiA2IDIgNyAwIDcgMCA2IDEgNiAxIDUgMiA1IDIgNCAzIDQiPjwvcG9seWdvbj4KPC9zdmc+");
  width: 12px;
  height: 10px;
}

.window-nav .buttons label.question:after, .alert-nav .buttons label.question:after {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjlweCIgdmlld0JveD0iMCAwIDYgOSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KPGcgZmlsbD0iIzFBMkMzNCI+Cjxwb2x5Z29uIHBvaW50cz0iMyA0IDMgMyA0IDMgNCAxIDIgMSAyIDMgMCAzIDAgMSAxIDEgMSAwIDUgMCA1IDEgNiAxIDYgMyA1IDMgNSA0IDQgNCA0IDYgMiA2IDIgNCI+PC9wb2x5Z29uPgo8cmVjdCB4PSIyIiB5PSI3IiB3aWR0aD0iMiIgaGVpZ2h0PSIyIj48L3JlY3Q+CjwvZz4KPC9zdmc+");
  width: 9px;
  height: 14px;
}

.window-nav .buttons label:not([disabled]):active, .alert-nav .buttons label:not([disabled]):active {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

.window-default .window-inside {
  width: 750px;
  height: 470px;
}

.window-content, .above-footer {
  height: 100%;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

#alert-blue-screen {
  z-index: 10000;
  color: #fff;
  background-color: #00a;
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

#alert-blue-screen .content {
  max-width: 610px;
  padding: 20px;
}

#alert-blue-screen h3, #alert-blue-screen p {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}

#alert-blue-screen h3, #alert-blue-screen p:last-child {
  text-align: center;
}

#alert-blue-screen h3 span {
  color: #00a;
  background-color: #aaa;
  padding: 2px 3px;
  display: inline-block;
}

#alert-blue-screen .blink {
  animation: 1s cubic-bezier(1, 0, 0, 1) infinite forwards blink;
  display: inline-block;
}

.alert-inside {
  min-width: unset;
  min-height: unset;
  resize: none;
  width: 320px;
  height: 180px;
}

.alert-content {
  height: calc(100% - 80px);
  padding: 0 24px;
}

.alert-content p {
  margin: 0;
  padding: 16px 0 0;
}

.alert-footer {
  padding: 0 24px 16px;
  display: flex;
}

#window-welcome .window-inside {
  height: 60vh;
}

@media screen and (width >= 568px) and (height <= 450px), screen and (width >= 768px) {
  #window-welcome .window-inside {
    width: 500px;
    height: 250px;
  }
}

@media only screen and (width >= 1000px) {
  #window-welcome .window-inside {
    width: 700px;
    height: 400px;
  }
}

#window-welcome .window-content {
  height: calc(100% - 79px);
  padding: 20px;
}

#window-welcome .window-footer {
  justify-content: flex-end;
  padding-right: 19px;
  display: flex;
}

#window-welcome .window-footer .button {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5px;
  display: flex;
  box-shadow: inset -3px -3px #263238, inset 1.5px 1.5px #263238, inset -4.5px -4.5px #a099a1, inset 3px 3px #fcfcfc;
}

#window-welcome .window-footer .button:active {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #263238, inset -3px -3px #a099a1, inset 3px 3px #a099a1;
}

#window-welcome .information-container {
  background: #fcfcfc;
  width: 100%;
  height: 100%;
  padding: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1;
}

#window-welcome .information {
  width: 100%;
  height: 100%;
  padding: 20px;
  line-height: 1.4;
  overflow-y: scroll;
}

#window-welcome .information h3, #window-welcome .information p, #window-welcome .information ul {
  margin: 1em 0 0;
}

#window-welcome .information h3:first-child {
  margin-top: 0;
}

#window-welcome .information ul {
  padding-left: 10px;
  list-style-type: "–";
}

#window-welcome .information ul li {
  padding-left: .5em;
}

#window-welcome .information ul li:not(:first-child) {
  margin-top: .25em;
}

.list-container {
  background: #fff;
  height: calc(100% - 30px);
  margin-top: 2px;
  padding: 3px;
  overflow-y: auto;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1, inset -3px -3px #cac6cb, inset 3px 3px #263238;
}

.list-container p {
  padding: 0 12px;
  line-height: 1.4;
}

.list {
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
  display: flex;
  overflow: auto;
}

.list li {
  position: relative;
}

.list label:not(.label-toggle) {
  white-space: nowrap;
  align-items: center;
  padding-bottom: 3px;
  display: flex;
  position: relative;
}

.list label:not(.label-toggle):not(.folders label):after {
  z-index: 2;
  width: 24px;
  height: 24px;
}

.list label:not(.label-toggle):before {
  z-index: 2;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  display: block;
  position: relative;
}

.list label:not(.label-toggle):active span, .list label:not(.label-toggle):focus span {
  color: #fff;
  background: #b2738e;
  border: 1px dotted #d1ae87;
}

@media (width >= 500px) {
  .list label:not(.label-toggle):active span, .list label:not(.label-toggle):focus span {
    border-width: 1.5px;
  }
}

.window-content .list:not(.folders .list) {
  grid-template-columns: repeat(auto-fill, 120px);
  row-gap: 24px;
  padding: 9px 0;
  display: grid !important;
}

.window-content .list:not(.folders .list) li label {
  white-space: unset;
  text-align: center;
  height: 98px;
  line-height: 1.3;
}

.window-content .list:not(.folders .list) li label:before {
  margin-bottom: 6px;
  margin-right: 0;
}

.window-content .list:not(.folders .list) li label:active span, .window-content .list:not(.folders .list) li label:focus span {
  color: #fff;
  background: #b2738e;
  border: 1px dotted #d1ae87;
}

@media (width >= 500px) {
  .window-content .list:not(.folders .list) li label:active span, .window-content .list:not(.folders .list) li label:focus span {
    border-width: 1.5px;
  }
}

.window-content .label-computer-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-computer-small:not(.folders label):before, .window-content .label-computer-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-computer-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-computer-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-computer-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-computer-small:not(.folders label):before {
  background-image: url("computer.49c58c1b.png");
}

.window-content .label-cd-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-cd-small:not(.folders label):before, .window-content .label-cd-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-cd-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-cd-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-cd-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-cd-small:not(.folders label):before {
  background-image: url("cd.a8dc0ed0.png");
}

.window-content .label-modems-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-modems-small:not(.folders label):before, .window-content .label-modems-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-modems-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-modems-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-modems-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-modems-small:not(.folders label):before {
  background-image: url("modems.b3ebdf4d.png");
}

.window-content .label-joystick-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-joystick-small:not(.folders label):before, .window-content .label-joystick-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-joystick-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-joystick-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-joystick-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-joystick-small:not(.folders label):before {
  background-image: url("joystick.475924ac.png");
}

.window-content .label-multimedia-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-multimedia-small:not(.folders label):before, .window-content .label-multimedia-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-multimedia-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-multimedia-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-multimedia-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-multimedia-small:not(.folders label):before {
  background-image: url("multimedia.800ee850.png");
}

.window-content .label-skip-awgs-x2-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-skip-awgs-x2-small:not(.folders label):before, .window-content .label-skip-awgs-x2-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-skip-awgs-x2-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-skip-awgs-x2-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-skip-awgs-x2-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-skip-awgs-x2-small:not(.folders label):before {
  background-image: url("skip-awgs-x2.482fa318.png");
}

.window-content .label-tryb-sporth-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-tryb-sporth-small:not(.folders label):before, .window-content .label-tryb-sporth-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-tryb-sporth-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-tryb-sporth-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-tryb-sporth-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-tryb-sporth-small:not(.folders label):before {
  background-image: url("tryb-sporth.1125c64d.png");
}

.window-content .label-wordpad-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.window-content .label-wordpad-small:not(.folders label):before, .window-content .label-wordpad-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.window-content .label-wordpad-small:not(.folders label):before {
  margin-bottom: 6px;
}

.window-content .label-wordpad-small:not(.folders label):after {
  left: 36px;
}

.window-content .label-wordpad-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.window-content .label-wordpad-small:not(.folders label):before {
  background-image: url("wordpad.da5c380e.png");
}

*, :before, :after {
  cursor: default;
  box-sizing: border-box;
  image-rendering: pixelated;
  text-underline-offset: 1px;
  cursor: url("cursor.953eae2f.png") 0 0, pointer;
  cursor: -webkit-image-set(url("cursor.953eae2f.png") 1x, url("cursor@2x.9066f769.png") 2x) 0 0, pointer;
  font-family: MS Sans Serif, Tahoma, Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-decoration-thickness: 1.5px;
}

:not(textarea, input) {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
}

:focus {
  outline: none;
}

::selection {
  background: none;
}

body, html {
  height: 100%;
}

body {
  color: #263238;
  background-color: #fbe6e2;
  margin: 0;
  line-height: 1;
  overflow: hidden;
}

textarea {
  resize: none;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  display: block;
  overflow: scroll;
}

textarea::selection {
  -webkit-tap-highlight-color: #577783;
  color: #fff;
  background-color: #577783;
}

.textarea-container {
  padding: 3px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1, inset -3px -3px #cac6cb, inset 3px 3px #263238;
}

[hidden] {
  appearance: none;
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

menu, ul:not(.information ul), ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.underline > li > a:not(.no-line):first-letter, .underline > li > a:not(.no-line) > span:first-letter, .underline > li > a:not(.no-line) .line:first-letter, .underline > li > label:not(.no-line):first-letter, .underline > li > label:not(.no-line) > span:first-letter, .underline > li > label:not(.no-line) .line:first-letter {
  text-decoration: underline;
}

.underline > li > a:not(.no-line) > span span:not(.line):first-letter, .underline > li > label:not(.no-line) > span span:not(.line):first-letter {
  text-decoration: none;
}

.underline > li > a:not(.no-line) .line, .underline > li > label:not(.no-line) .line {
  display: inline-block;
}

label:before, label:after, a:before, a:after {
  content: "";
  background-position: center;
  background-size: contain;
}

label[tabindex] span {
  padding: 1px 2px;
}

label[tabindex] span span {
  padding: 0;
}

[class*="label"]:not(.folders label):not(.label-nested):after {
  opacity: 0;
  background-color: #0000;
  background-image: linear-gradient(45deg, #b2738e 25%, #0000 25% 75%, #b2738e 75%, #b2738e), linear-gradient(45deg, #b2738e 25%, #0000 25% 75%, #b2738e 75%, #b2738e);
  background-position: 0 0, 1.5px 1.5px;
  background-size: 3px 3px;
  display: block;
  position: absolute;
  top: 0;
  mask-position: center;
  mask-size: cover;
}

[class*="label"]:not(.folders label):not(.label-nested)[tabindex]:active:after, [class*="label"]:not(.folders label):not(.label-nested)[tabindex]:focus:after {
  opacity: 1;
}

.label-nested:after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWElEQVQokWP8//8/AymAiSTVDAwMLOgCasYW/xkYGBhh/FtnTxBlA053Ymjg/vcTryYMDV+Z2PHahNPTUJsY0cVxavjKxI6hGJ8GrIoZGBgYGGkecSRrAABJOBMT7b9GFQAAAABJRU5ErkJggg==");
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 6px;
}

.label-nested:hover:after, li:hover > .label-nested:after {
  filter: invert() brightness(200%);
}

#window-taskbar {
  bottom: 48px;
  left: 3px;
}

#window-taskbar > .window-inside {
  resize: none;
  width: 510px;
  height: 600px;
}

#window-taskbar > .window-inside > .window-content {
  height: calc(100% - 76px);
  padding: 12px 9px 10px;
}

#window-taskbar .window-footer {
  justify-content: flex-end;
  width: 100%;
  padding: 0 9px;
  display: flex;
}

#window-taskbar .tab-container {
  height: 100%;
}

#window-taskbar .tab-group {
  display: flex;
}

#window-taskbar .tab {
  z-index: 0;
  background-color: #cac6cb;
  margin-top: 3px;
  padding: 0 12px;
  line-height: 32px;
  display: block;
  position: relative;
}

#window-taskbar .tab:first-child {
  transform: translateX(3px);
}

#window-taskbar .tab:not(:first-child) {
  margin-left: -3px;
  transform: translateX(-3px);
}

#window-taskbar .tab:before, #window-taskbar .tab:after, #window-taskbar .tab span:before, #window-taskbar .tab span:after {
  content: "";
  display: block;
  position: absolute;
}

#window-taskbar .tab:before {
  border-top: 1.5px solid #fcfcfc;
  width: calc(100% - 6px);
  height: 100%;
  top: 0;
  left: 3px;
}

#window-taskbar .tab:after {
  border-left: 1.5px solid #fcfcfc;
  border-right: 1.5px solid #263238;
  width: 100%;
  height: calc(100% - 3px);
  bottom: 0;
  left: 0;
  box-shadow: inset -1.5px 0 #a099a1;
}

#window-taskbar .tab span:before, #window-taskbar .tab span:after {
  width: 1.5px;
  height: 1.5px;
  top: 1.5px;
}

#window-taskbar .tab span:before {
  background-color: #fcfcfc;
  left: 1.5px;
}

#window-taskbar .tab span:after {
  background-color: #263238;
  right: 1.5px;
}

#window-taskbar #taskbar-options:checked ~ .window-inside .tab-taskbar-options {
  z-index: 12;
  margin-top: 0;
  transform: none;
}

#window-taskbar #taskbar-options:checked ~ .window-inside .tabpanel.taskbar-options {
  z-index: 12;
}

#window-taskbar #start-menu-programs:checked ~ .window-inside .tab-start-menu-programs {
  z-index: 12;
  margin-top: 0;
  transform: none;
}

#window-taskbar #start-menu-programs:checked ~ .window-inside .tabpanel.start-menu-programs {
  z-index: 12;
}

#window-taskbar .tabpanel-group {
  z-index: 0;
  height: calc(100% - 30.5px);
  margin-top: -1.5px;
  position: relative;
}

#window-taskbar .tabpanel {
  background-color: #cac6cb;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #cac6cb;
}

#window-taskbar .taskbar-options {
  padding: 32px;
}

#window-taskbar .preview-container {
  background: #fbe6e2;
  place-items: center;
  width: 100%;
  height: 200px;
  display: grid;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1;
}

#window-taskbar .preview {
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  position: relative;
  overflow: hidden;
}

#window-taskbar .preview * {
  pointer-events: none;
}

#window-taskbar .preview .window {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  bottom: 22px;
  left: calc(100% - 96px);
}

#window-taskbar .preview .window-inside {
  width: 300px;
  height: 200px;
}

#window-taskbar .preview .window-content, #window-taskbar .preview .list-container {
  height: 100%;
}

#window-taskbar .preview .list {
  display: block;
}

#window-taskbar .preview .list label {
  width: 116px;
  height: 98px;
  white-space: unset;
  text-align: center;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
  display: flex;
  position: relative;
}

#window-taskbar .preview .list label:before {
  width: 48px;
  height: 48px;
  margin-bottom: 6px;
  margin-right: 0;
}

#window-taskbar .preview .start-menu, #window-taskbar .preview .start-menu-outline {
  width: calc(100% - 8px);
  bottom: 1px;
  left: 4px;
}

#window-taskbar .preview .start-menu-outline {
  visibility: hidden;
  z-index: 10;
  border: 1.5px dotted #8bb29a;
  border-bottom: 0;
  height: 44px;
  position: absolute;
}

#window-taskbar .preview .start-menu {
  justify-content: space-between;
  padding: 0 2px 0 1px;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #dedcde;
}

#window-taskbar .preview .start-menu .label-start {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#window-taskbar .preview .start-menu .menu-container {
  visibility: visible;
  z-index: 2;
  bottom: 44px;
  left: 0;
}

#window-taskbar .preview .start-menu .time {
  align-items: center;
  width: auto;
  padding: 0 10px;
  display: flex;
}

#window-taskbar .preview .start-menu .time:before {
  content: "";
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

#window-taskbar .options {
  width: 100%;
}

#window-taskbar .options label {
  align-items: center;
  margin-top: 32px;
  display: flex;
  position: relative;
}

#window-taskbar .options label:before, #window-taskbar .options label:after {
  width: 20px;
  height: 20px;
  display: block;
}

#window-taskbar .options label:before {
  background-color: #fff;
  margin-right: 8px;
  padding: 3px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1, inset -3px -3px #cac6cb, inset 3px 3px #263238;
}

#window-taskbar .options label:after {
  visibility: hidden;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxwb2x5Z29uIHN0cm9rZT0ibm9uZSIgZmlsbD0iIzI2MzIzOCIgcG9pbnRzPSI0IDYgMyA2IDMgNyAyIDcgMiA2IDEgNiAxIDUgMCA1IDAgMiAxIDIgMSAzIDIgMyAyIDQgMyA0IDMgMyA0IDMgNCAyIDUgMiA1IDEgNiAxIDYgMCA3IDAgNyAzIDYgMyA2IDQgNSA0IDUgNSA0IDUiPjwvcG9seWdvbj4KPC9zdmc+");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  position: absolute;
  left: 0;
}

#window-taskbar .options label > span {
  border: 1.5px dotted #0000;
  height: 20px;
  line-height: 1;
}

#window-taskbar .options label:focus > span {
  border: 1.5px dotted;
}

#window-taskbar .start-menu-programs {
  padding: 0 20px 20px;
}

#window-taskbar .start-menu-programs form {
  width: 100%;
  display: block;
}

#window-taskbar .start-menu-programs fieldset {
  border: 0;
  width: 100%;
  margin-top: 20px;
  padding: 32px 32px 20px;
  display: block;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1, inset -3px -3px #a099a1, inset 3px 3px #fcfcfc;
}

#window-taskbar .start-menu-programs legend {
  background-color: #cac6cb;
  margin-left: -22px;
  padding: 0 5px;
  transform: translateY(2px);
}

#window-taskbar .start-menu-programs p {
  align-items: flex-start;
  width: calc(100% - 20px);
  margin: 0;
  line-height: 1.3;
  display: flex;
}

#window-taskbar .start-menu-programs p:before {
  content: "";
  background-size: contain;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 24px;
  display: block;
}

#window-taskbar .start-menu-programs .menu-options {
  justify-content: flex-end;
  display: flex;
}

#window-taskbar .start-menu-programs .menu-options li:not(:first-child) {
  margin-left: 10px;
}

#window-taskbar .start-menu-programs .customize-start-menu .menu-options {
  margin-top: 48px;
}

#window-taskbar .start-menu-programs .documents-menu .menu-options {
  margin-top: 20px;
}

#option-always-on-top:checked ~ .window-inside label[for="option-always-on-top"]:after, #option-auto-hide:checked ~ .window-inside label[for="option-auto-hide"]:after, #option-show-small-icons:checked ~ .window-inside label[for="option-show-small-icons"]:after, #option-show-clock:checked ~ .window-inside label[for="option-show-clock"]:after {
  visibility: visible;
}

#option-always-on-top:not(:checked) ~ .window-inside .preview .window {
  z-index: 11;
}

#global-option-always-on-top:not(:checked) ~ main #start-menu {
  z-index: unset;
}

#global-option-always-on-top:not(:checked) ~ main #start-menu .menu-container, #start:checked ~ #global-option-always-on-top:not(:checked) ~ main #start-menu {
  z-index: 1000000;
}

#start:checked ~ #global-option-always-on-top:not(:checked) ~ main #start-menu .menu-container {
  z-index: unset;
}

#option-auto-hide:checked ~ .window-inside .preview .start-menu-outline {
  visibility: visible;
}

#option-auto-hide:checked ~ .window-inside .preview .start-menu {
  height: 10px;
  box-shadow: inset -1.5px 0 #263238, inset 1.5px 1.5px #fcfcfc, inset -3px 0 #a099a1, inset 3px 3px #dedcde;
}

#option-auto-hide:checked ~ .window-inside .preview .start-menu > * {
  visibility: hidden;
}

#global-option-auto-hide:checked ~ main:not(#start:checked ~ main) .start-menu {
  transform: translateY(100%);
}

#global-option-auto-hide:checked ~ main:not(#start:checked ~ main) .start-menu:hover {
  transform: none;
}

#global-option-auto-hide:checked ~ main:not(#start:checked ~ main) #start-menu-outline {
  visibility: visible;
}

#global-option-auto-hide:checked ~ main:not(#start:checked ~ main) #start-menu-outline:hover + .start-menu {
  transform: none;
}

#option-show-small-icons:checked ~ .window-inside .preview .menu-container:before, #global-option-show-small-icons:checked ~ main #start-menu .menu-container:before {
  display: none;
}

#option-show-small-icons:checked ~ .window-inside .preview .main-menu > li:last-child, #global-option-show-small-icons:checked ~ main #start-menu .main-menu > li:last-child {
  padding-top: 8px;
}

#option-show-small-icons:checked ~ .window-inside .preview .main-menu > li > a, #option-show-small-icons:checked ~ .window-inside .preview .main-menu > li > label, #global-option-show-small-icons:checked ~ main #start-menu .main-menu > li > a, #global-option-show-small-icons:checked ~ main #start-menu .main-menu > li > label {
  width: 175px;
  height: 34px;
}

#option-show-small-icons:checked ~ .window-inside .preview .main-menu > li > a:before, #option-show-small-icons:checked ~ .window-inside .preview .main-menu > li > label:before, #global-option-show-small-icons:checked ~ main #start-menu .main-menu > li > a:before, #global-option-show-small-icons:checked ~ main #start-menu .main-menu > li > label:before {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

#option-show-clock:not(:checked) ~ .window-inside .preview .time:before {
  margin-right: 0;
}

#option-show-clock:not(:checked) ~ .window-inside .preview time, #global-option-show-clock:not(:checked) ~ main #start-menu .time {
  display: none;
}

#window-printers .window-inside {
  width: 480px;
  height: 390px;
}

#window-control-panel .window-inside {
  width: 520px;
  height: 480px;
}

#window-explorer .window-nav h3 > span {
  height: 28px;
  position: relative;
}

#window-explorer .window-nav h3 > span span {
  visibility: hidden;
  position: absolute;
  top: 0;
}

#window-explorer .window-content {
  flex-wrap: wrap;
  display: flex;
}

#window-explorer .window-content h4, #window-explorer .window-content h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 6px;
  font-weight: normal;
  line-height: 28px;
  overflow: hidden;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1;
}

#window-explorer .window-content .folders {
  width: 250px;
}

#window-explorer .window-content .folders .long {
  min-width: 180px;
}

#window-explorer .window-content .folders label:not(.label-toggle):after {
  z-index: 1;
  border-top: 1px dotted #a099a1;
  width: 17px;
  display: block;
  position: absolute;
  left: -17px;
}

@media (width >= 500px) {
  #window-explorer .window-content .folders label:not(.label-toggle):after {
    border-top-width: 1.5px;
    width: 14px;
    left: -14px;
  }
}

#window-explorer .window-content .folders-content {
  width: calc(100% - 254px);
  margin-left: 4px;
  position: relative;
}

#window-explorer .window-content .content {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
}

#window-explorer .window-content .content .list li {
  min-width: 210px;
}

#welcome main #window-welcome {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#welcome main .tab-welcome {
  display: flex;
}

#welcome main .menu .label-welcome {
  pointer-events: none;
}

#welcome.active.no-tab ~ main #window-welcome {
  z-index: 10;
}

#welcome.active.no-tab ~ main #window-welcome .window-nav {
  background-color: #577783;
}

#welcome.active.no-tab ~ main .window[data-window]:not(#window-welcome) {
  z-index: 2;
}

#welcome.active.no-tab ~ main .tab-welcome label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#welcome.active.no-tab ~ main .activate:not(.tab-welcome .activate) {
  z-index: 1;
}

#welcome.active.no-tab ~ main .tab label:not(.tab-welcome label) {
  z-index: 0;
}

#welcome-minimize:active ~ main #window-welcome .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#welcome-minimize:checked ~ main #window-welcome {
  transform: scale(0);
}

#welcome-maximize:active ~ main #window-welcome .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#welcome-maximize:checked ~ main #window-welcome {
  left: unset !important;
  top: 0 !important;
}

#welcome-maximize:checked ~ main #window-welcome .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#welcome-maximize:checked ~ main #window-welcome .window-inside .maximize:before, #welcome-maximize:checked ~ main #window-welcome .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#welcome-maximize:checked ~ main #window-welcome .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#welcome-maximize:checked ~ main #window-welcome .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#taskbar main #window-taskbar {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#taskbar main .tab-taskbar {
  display: flex;
}

#taskbar main .menu .label-taskbar {
  pointer-events: none;
}

#taskbar.active.no-tab ~ main #window-taskbar {
  z-index: 10;
}

#taskbar.active.no-tab ~ main #window-taskbar .window-nav {
  background-color: #577783;
}

#taskbar.active.no-tab ~ main .window[data-window]:not(#window-taskbar) {
  z-index: 2;
}

#taskbar.active.no-tab ~ main .tab-taskbar label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#taskbar.active.no-tab ~ main .activate:not(.tab-taskbar .activate) {
  z-index: 1;
}

#taskbar.active.no-tab ~ main .tab label:not(.tab-taskbar label) {
  z-index: 0;
}

#taskbar-minimize:active ~ main #window-taskbar .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#taskbar-minimize:checked ~ main #window-taskbar {
  transform: scale(0);
}

#taskbar-maximize:active ~ main #window-taskbar .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#taskbar-maximize:checked ~ main #window-taskbar {
  left: unset !important;
  top: 0 !important;
}

#taskbar-maximize:checked ~ main #window-taskbar .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#taskbar-maximize:checked ~ main #window-taskbar .window-inside .maximize:before, #taskbar-maximize:checked ~ main #window-taskbar .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#taskbar-maximize:checked ~ main #window-taskbar .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#taskbar-maximize:checked ~ main #window-taskbar .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#printers main #window-printers {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#printers main .tab-printers {
  display: flex;
}

#printers main .menu .label-printers {
  pointer-events: none;
}

#printers.active.no-tab ~ main #window-printers {
  z-index: 10;
}

#printers.active.no-tab ~ main #window-printers .window-nav {
  background-color: #577783;
}

#printers.active.no-tab ~ main .window[data-window]:not(#window-printers) {
  z-index: 2;
}

#printers.active.no-tab ~ main .tab-printers label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#printers.active.no-tab ~ main .activate:not(.tab-printers .activate) {
  z-index: 1;
}

#printers.active.no-tab ~ main .tab label:not(.tab-printers label) {
  z-index: 0;
}

#printers-minimize:active ~ main #window-printers .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#printers-minimize:checked ~ main #window-printers {
  transform: scale(0);
}

#printers-maximize:active ~ main #window-printers .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#printers-maximize:checked ~ main #window-printers {
  left: unset !important;
  top: 0 !important;
}

#printers-maximize:checked ~ main #window-printers .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#printers-maximize:checked ~ main #window-printers .window-inside .maximize:before, #printers-maximize:checked ~ main #window-printers .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#printers-maximize:checked ~ main #window-printers .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#printers-maximize:checked ~ main #window-printers .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#control-panel main #window-control-panel {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#control-panel main .tab-control-panel {
  display: flex;
}

#control-panel main .menu .label-control-panel {
  pointer-events: none;
}

#control-panel.active.no-tab ~ main #window-control-panel {
  z-index: 10;
}

#control-panel.active.no-tab ~ main #window-control-panel .window-nav {
  background-color: #577783;
}

#control-panel.active.no-tab ~ main .window[data-window]:not(#window-control-panel) {
  z-index: 2;
}

#control-panel.active.no-tab ~ main .tab-control-panel label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#control-panel.active.no-tab ~ main .activate:not(.tab-control-panel .activate) {
  z-index: 1;
}

#control-panel.active.no-tab ~ main .tab label:not(.tab-control-panel label) {
  z-index: 0;
}

#control-panel-minimize:active ~ main #window-control-panel .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#control-panel-minimize:checked ~ main #window-control-panel {
  transform: scale(0);
}

#control-panel-maximize:active ~ main #window-control-panel .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#control-panel-maximize:checked ~ main #window-control-panel {
  left: unset !important;
  top: 0 !important;
}

#control-panel-maximize:checked ~ main #window-control-panel .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#control-panel-maximize:checked ~ main #window-control-panel .window-inside .maximize:before, #control-panel-maximize:checked ~ main #window-control-panel .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#control-panel-maximize:checked ~ main #window-control-panel .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#control-panel-maximize:checked ~ main #window-control-panel .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#computer main #window-computer {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#computer main .tab-computer {
  display: flex;
}

#computer main .menu .label-computer {
  pointer-events: none;
}

#computer.active.no-tab ~ main #window-computer {
  z-index: 10;
}

#computer.active.no-tab ~ main #window-computer .window-nav {
  background-color: #577783;
}

#computer.active.no-tab ~ main .window[data-window]:not(#window-computer) {
  z-index: 2;
}

#computer.active.no-tab ~ main .tab-computer label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#computer.active.no-tab ~ main .activate:not(.tab-computer .activate) {
  z-index: 1;
}

#computer.active.no-tab ~ main .tab label:not(.tab-computer label) {
  z-index: 0;
}

#computer-minimize:active ~ main #window-computer .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#computer-minimize:checked ~ main #window-computer {
  transform: scale(0);
}

#computer-maximize:active ~ main #window-computer .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#computer-maximize:checked ~ main #window-computer {
  left: unset !important;
  top: 0 !important;
}

#computer-maximize:checked ~ main #window-computer .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#computer-maximize:checked ~ main #window-computer .window-inside .maximize:before, #computer-maximize:checked ~ main #window-computer .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#computer-maximize:checked ~ main #window-computer .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#computer-maximize:checked ~ main #window-computer .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#notepad main #window-notepad {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#notepad main .tab-notepad {
  display: flex;
}

#notepad main .menu .label-notepad {
  pointer-events: none;
}

#notepad.active.no-tab ~ main #window-notepad {
  z-index: 10;
}

#notepad.active.no-tab ~ main #window-notepad .window-nav {
  background-color: #577783;
}

#notepad.active.no-tab ~ main .window[data-window]:not(#window-notepad) {
  z-index: 2;
}

#notepad.active.no-tab ~ main .tab-notepad label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#notepad.active.no-tab ~ main .activate:not(.tab-notepad .activate) {
  z-index: 1;
}

#notepad.active.no-tab ~ main .tab label:not(.tab-notepad label) {
  z-index: 0;
}

#notepad-minimize:active ~ main #window-notepad .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#notepad-minimize:checked ~ main #window-notepad {
  transform: scale(0);
}

#notepad-maximize:active ~ main #window-notepad .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#notepad-maximize:checked ~ main #window-notepad {
  left: unset !important;
  top: 0 !important;
}

#notepad-maximize:checked ~ main #window-notepad .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#notepad-maximize:checked ~ main #window-notepad .window-inside .maximize:before, #notepad-maximize:checked ~ main #window-notepad .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#notepad-maximize:checked ~ main #window-notepad .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#notepad-maximize:checked ~ main #window-notepad .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

#explorer main #window-explorer {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#explorer main .tab-explorer {
  display: flex;
}

#explorer main .menu .label-explorer {
  pointer-events: none;
}

#explorer.active.no-tab ~ main #window-explorer {
  z-index: 10;
}

#explorer.active.no-tab ~ main #window-explorer .window-nav {
  background-color: #577783;
}

#explorer.active.no-tab ~ main .window[data-window]:not(#window-explorer) {
  z-index: 2;
}

#explorer.active.no-tab ~ main .tab-explorer label {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#explorer.active.no-tab ~ main .activate:not(.tab-explorer .activate) {
  z-index: 1;
}

#explorer.active.no-tab ~ main .tab label:not(.tab-explorer label) {
  z-index: 0;
}

#explorer-minimize:active ~ main #window-explorer .window-inside .minimize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#explorer-minimize:checked ~ main #window-explorer {
  transform: scale(0);
}

#explorer-maximize:active ~ main #window-explorer .window-inside .maximize {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#explorer-maximize:checked ~ main #window-explorer {
  left: unset !important;
  top: 0 !important;
}

#explorer-maximize:checked ~ main #window-explorer .window-inside {
  max-width: calc(100vw - 12px);
  width: calc(100vw - 12px) !important;
  height: calc(100vh - 56px) !important;
}

#explorer-maximize:checked ~ main #window-explorer .window-inside .maximize:before, #explorer-maximize:checked ~ main #window-explorer .window-inside .maximize:after {
  width: 9px;
  height: 9px;
}

#explorer-maximize:checked ~ main #window-explorer .window-inside .maximize:before {
  visibility: visible;
  top: 3px;
  right: 6px;
}

#explorer-maximize:checked ~ main #window-explorer .window-inside .maximize:after {
  bottom: 4px;
  left: 4px;
}

.menu-status-bar:checked ~ .window-inside .label-check.label-status-bar:before {
  visibility: visible;
}

.menu-status-bar:checked ~ .window-inside .window-footer {
  display: flex;
}

.menu-status-bar:checked ~ .window-inside .above-footer {
  height: calc(100% - 29px);
}

.menu-large-icons:checked ~ .window-inside .label-check.label-large-icons:before {
  visibility: visible;
}

.menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) {
  grid-template-columns: repeat(auto-fill, 120px);
  row-gap: 24px;
  padding: 9px 0;
  display: grid !important;
}

.menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label {
  white-space: unset;
  text-align: center;
  height: 98px;
  line-height: 1.3;
}

.menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label:before {
  margin-bottom: 6px;
  margin-right: 0;
}

.menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label:active span, .menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label:focus span {
  color: #fff;
  background: #b2738e;
  border: 1px dotted #d1ae87;
}

@media (width >= 500px) {
  .menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label:active span, .menu-large-icons:checked ~ .window-inside .window-content .list:not(.folders .list) li label:focus span {
    border-width: 1.5px;
  }
}

.menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-computer-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-cd-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-modems-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-joystick-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-multimedia-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-skip-awgs-x2-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-tryb-sporth-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label) {
  flex-direction: column;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label):before, .menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label):after {
  width: 48px;
  height: 48px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label):before {
  margin-bottom: 6px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label):after {
  left: 36px;
}

.menu-large-icons:checked ~ .window-inside .window-content .label-wordpad-small:not(.folders label) span {
  border: 1.5px solid #0000;
  padding: 1px;
}

.menu-small-icons:checked ~ .window-inside .label-check.label-small-icons:before {
  visibility: visible;
}

.menu-small-icons:checked ~ .window-inside .window-content .list:not(.folders .list) {
  flex-direction: row;
}

.menu-small-icons:checked ~ .window-inside .window-content .list:not(.folders .list) label {
  width: 210px;
}

.menu-list-icons:checked ~ .window-inside .label-check.label-list-icons:before {
  visibility: visible;
}

.menu-list-icons:checked ~ .window-inside .window-content .list:not(.folders list) {
  flex-direction: column;
}

#alerts {
  z-index: 1000001;
}

#error-blue-screen main #alert-blue-screen, #error-diskette-a main #alert-diskette-a, #error-diskette-b main #alert-diskette-b {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

#toggle-computer:checked ~ main #window-explorer .toggle-computer:after {
  visibility: hidden;
}

#toggle-computer:checked ~ main #window-explorer .toggle-computer ~ menu {
  display: block;
}

#toggle-disk:checked ~ main #window-explorer .toggle-disk:after {
  visibility: hidden;
}

#toggle-disk:checked ~ main #window-explorer .toggle-disk ~ menu {
  display: block;
}

#toggle-program-files:checked ~ main #window-explorer .toggle-program-files:after {
  visibility: hidden;
}

#toggle-program-files:checked ~ main #window-explorer .toggle-program-files ~ menu {
  display: block;
}

#toggle-windows:checked ~ main #window-explorer .toggle-windows:after {
  visibility: hidden;
}

#toggle-windows:checked ~ main #window-explorer .toggle-windows ~ menu {
  display: block;
}

#explorer-desktop:checked ~ main #window-explorer .title-explorer-desktop, #explorer-desktop:checked ~ main #window-explorer .content-explorer-desktop, #explorer-desktop:checked ~ main #window-explorer .explorer-number-desktop, #explorer-desktop:checked ~ main #window-explorer .explorer-space-desktop {
  visibility: visible;
}

#explorer-desktop:checked ~ main #window-explorer .label-folder-small[for="explorer-desktop"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-desktop:checked ~ main #window-explorer .content-explorer-desktop {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-computer:checked ~ main #window-explorer .title-explorer-computer, #explorer-computer:checked ~ main #window-explorer .content-explorer-computer, #explorer-computer:checked ~ main #window-explorer .explorer-number-computer, #explorer-computer:checked ~ main #window-explorer .explorer-space-computer {
  visibility: visible;
}

#explorer-computer:checked ~ main #window-explorer .label-folder-small[for="explorer-computer"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-computer:checked ~ main #window-explorer .content-explorer-computer {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-disk:checked ~ main #window-explorer .title-explorer-disk, #explorer-disk:checked ~ main #window-explorer .content-explorer-disk, #explorer-disk:checked ~ main #window-explorer .explorer-number-disk, #explorer-disk:checked ~ main #window-explorer .explorer-space-disk {
  visibility: visible;
}

#explorer-disk:checked ~ main #window-explorer .label-folder-small[for="explorer-disk"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-disk:checked ~ main #window-explorer .content-explorer-disk {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-dos:checked ~ main #window-explorer .title-explorer-dos, #explorer-dos:checked ~ main #window-explorer .content-explorer-dos, #explorer-dos:checked ~ main #window-explorer .explorer-number-dos, #explorer-dos:checked ~ main #window-explorer .explorer-space-dos {
  visibility: visible;
}

#explorer-dos:checked ~ main #window-explorer .label-folder-small[for="explorer-dos"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-dos:checked ~ main #window-explorer .content-explorer-dos {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-program-files:checked ~ main #window-explorer .title-explorer-program-files, #explorer-program-files:checked ~ main #window-explorer .content-explorer-program-files, #explorer-program-files:checked ~ main #window-explorer .explorer-number-program-files, #explorer-program-files:checked ~ main #window-explorer .explorer-space-program-files {
  visibility: visible;
}

#explorer-program-files:checked ~ main #window-explorer .label-folder-small[for="explorer-program-files"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-program-files:checked ~ main #window-explorer .content-explorer-program-files {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-windows:checked ~ main #window-explorer .title-explorer-windows, #explorer-windows:checked ~ main #window-explorer .content-explorer-windows, #explorer-windows:checked ~ main #window-explorer .explorer-number-windows, #explorer-windows:checked ~ main #window-explorer .explorer-space-windows {
  visibility: visible;
}

#explorer-windows:checked ~ main #window-explorer .label-folder-small[for="explorer-windows"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-windows:checked ~ main #window-explorer .content-explorer-windows {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-control-panel:checked ~ main #window-explorer .title-explorer-control-panel, #explorer-control-panel:checked ~ main #window-explorer .content-explorer-control-panel, #explorer-control-panel:checked ~ main #window-explorer .explorer-number-control-panel, #explorer-control-panel:checked ~ main #window-explorer .explorer-space-control-panel {
  visibility: visible;
}

#explorer-control-panel:checked ~ main #window-explorer .label-folder-small[for="explorer-control-panel"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-control-panel:checked ~ main #window-explorer .content-explorer-control-panel {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-printers:checked ~ main #window-explorer .title-explorer-printers, #explorer-printers:checked ~ main #window-explorer .content-explorer-printers, #explorer-printers:checked ~ main #window-explorer .explorer-number-printers, #explorer-printers:checked ~ main #window-explorer .explorer-space-printers {
  visibility: visible;
}

#explorer-printers:checked ~ main #window-explorer .label-folder-small[for="explorer-printers"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-printers:checked ~ main #window-explorer .content-explorer-printers {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#explorer-recycle-bin:checked ~ main #window-explorer .title-explorer-recycle-bin, #explorer-recycle-bin:checked ~ main #window-explorer .content-explorer-recycle-bin, #explorer-recycle-bin:checked ~ main #window-explorer .explorer-number-recycle-bin, #explorer-recycle-bin:checked ~ main #window-explorer .explorer-space-recycle-bin {
  visibility: visible;
}

#explorer-recycle-bin:checked ~ main #window-explorer .label-folder-small[for="explorer-recycle-bin"]:before {
  background-image: url("folder-open-small.1fd642bc.png");
}

#explorer-recycle-bin:checked ~ main #window-explorer .content-explorer-recycle-bin {
  visibility: visible;
  opacity: 1;
  z-index: unset;
}

#start:focus ~ main > .start-menu .label-start, #start:active ~ main > .start-menu .label-start, #start:checked ~ main > .start-menu .label-start {
  outline-offset: -6px;
  outline: 1px dotted #263238;
}

@media (width >= 500px) {
  #start:focus ~ main > .start-menu .label-start, #start:active ~ main > .start-menu .label-start, #start:checked ~ main > .start-menu .label-start {
    outline-width: 1.5px;
  }
}

#start:active ~ main > .start-menu .label-start, #start:checked ~ main > .start-menu .label-start {
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

#start:checked ~ main > .start-menu .menu-container {
  visibility: visible;
  z-index: unset;
}

#start:checked ~ main > .start-menu .menu-container .sub-menu:hover {
  visibility: visible;
  z-index: 2;
}

#start:checked ~ main > .start-menu .menu-container .main-menu a:hover ~ .sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

#start-menu-outline {
  visibility: hidden;
  background-color: #a099a1;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.start-menu {
  z-index: 10;
  background-color: #cac6cb;
  align-items: center;
  width: 100%;
  height: 44px;
  padding-top: 3px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 1.5px #cac6cb, inset 0 3px #fcfcfc;
}

.start-menu .menu-container, .start-menu .sub-menu {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
  position: absolute;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #dedcde;
}

.start-menu .menu li {
  position: relative;
}

.start-menu .menu li:hover > label, .start-menu .menu li:hover > a {
  color: #fff;
  background-color: #577783;
}

.start-menu .menu label, .start-menu .menu a {
  align-content: center;
  align-items: center;
  width: 200px;
  height: 32px;
  padding-left: 8px;
  display: flex;
}

.start-menu .menu label:before, .start-menu .menu a:before {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.start-menu .menu label:hover, .start-menu .menu a:hover {
  color: #fff;
  background-color: #577783;
}

.start-menu .menu label.label-empty, .start-menu .menu a.label-empty {
  justify-content: center;
  padding-left: 0;
}

.start-menu .menu label.label-empty:before, .start-menu .menu label.label-empty:after, .start-menu .menu a.label-empty:before, .start-menu .menu a.label-empty:after {
  display: none;
}

.start-menu .menu label.label-disabled, .start-menu .menu label.label-disabled:hover, .start-menu .menu label.label-empty, .start-menu .menu label.label-empty:hover, .start-menu .menu a.label-disabled, .start-menu .menu a.label-disabled:hover, .start-menu .menu a.label-empty, .start-menu .menu a.label-empty:hover {
  color: #85888c;
}

.start-menu .menu-container {
  visibility: hidden;
  z-index: -1;
  align-items: stretch;
  display: flex;
  bottom: 38px;
  left: 4px;
}

.start-menu .menu-container:before {
  content: "";
  background-color: #8e9699;
  width: 32px;
  display: block;
}

.start-menu .menu-container .sub-menu {
  visibility: hidden;
  z-index: -1;
  margin: 0;
  display: block;
  top: -4px;
  left: calc(100% - 4px);
}

.start-menu .menu-container .main-menu > li:nth-last-child(2) {
  border-bottom: 1.5px solid #a099a1;
}

.start-menu .menu-container .main-menu > li:last-child {
  border-top: 1.5px solid #fcfcfc;
}

.start-menu .menu-container .main-menu > li > label, .start-menu .menu-container .main-menu > li > a {
  height: 50px;
}

.start-menu .menu-container .main-menu > li > label:before, .start-menu .menu-container .main-menu > li > a:before {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.start-menu .label-start {
  margin-left: 4px;
  font-weight: bold;
}

.start-menu .label-start:before {
  background-image: url("start.547f32e4.png");
  background-size: 24px 21px;
  width: 24px;
  height: 21px;
  margin-right: 5px;
}

.start-menu .label-start, .start-menu .tabs label {
  z-index: 4;
  background: #cac6cb;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #cac6cb;
  padding: 0 6px !important;
}

.start-menu .tabs {
  width: calc(100% - 185px);
  display: flex;
}

.start-menu .tabs .tab {
  flex-basis: 200px;
  margin-left: 4px;
  display: none;
  position: relative;
  overflow: hidden;
}

.start-menu .tabs .activate {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.start-menu .tabs label {
  z-index: 1;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.start-menu .tabs label span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.start-menu .tabs label:before {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 3px;
}

.start-menu .tabs label:active {
  z-index: 4;
  background-color: #0000;
  background-image: linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc), linear-gradient(45deg, #fcfcfc 25%, #0000 25% 75%, #fcfcfc 75%, #fcfcfc);
  background-position: 0 0, 1.5px 1.5px;
  background-repeat: repeat;
  background-size: 3px 3px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5px;
  padding-right: 1.5px;
  display: flex;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #263238, inset -3px -3px #cac6cb, inset 3px 3px #a099a1;
}

.start-menu .time {
  white-space: nowrap;
  text-overflow: ellipsis;
  place-items: center;
  width: 100px;
  height: 34px;
  margin: 0 3px;
  display: grid;
  overflow: hidden;
  box-shadow: inset -1.5px -1.5px #fcfcfc, inset 1.5px 1.5px #a099a1;
}

#start-menu {
  z-index: 1000000;
}

::-webkit-scrollbar {
  width: 24px;
  height: 24px;
}

::-webkit-scrollbar-track {
  background-color: #fcfcfc;
  background-image: linear-gradient(45deg, #efeef0 25%, #0000 25% 75%, #efeef0 75%, #efeef0), linear-gradient(45deg, #efeef0 25%, #0000 25% 75%, #efeef0 75%, #efeef0);
  background-position: 0 0, 1px 1px;
  background-size: 2px 2px;
}

::-webkit-scrollbar-thumb {
  z-index: 1;
  background-color: #cac6cb;
  width: 24px;
  height: 24px;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #dedcde;
}

::-webkit-scrollbar-corner {
  background-color: #cac6cb;
}

::-webkit-resizer {
  width: 24px;
  height: 24px;
  image-rendering: pixelated;
  background-color: #0000;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAN0lEQVR4Ae3MgQUAMBRDwU5fFF05lb/CARTBw2Ulof0DxPtcwp3hNuEYnjbcEW4TjuFpwx3h9gMWGgZ2Y/PT2gAAAABJRU5ErkJggg==");
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-button {
  width: 24px;
  height: 24px;
  image-rendering: pixelated;
  background-color: #cac6cb;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  display: block;
  box-shadow: inset -1.5px -1.5px #263238, inset 1.5px 1.5px #fcfcfc, inset -3px -3px #a099a1, inset 3px 3px #dedcde;
}

::-webkit-scrollbar-button:active {
  background-position: 2px 2px;
}

::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAPUlEQVQokWP8//8/AymAiSTVhDSoGVtgWI9TAzbFODXgUoxVAz7FWDXcOnuCkSQNhDTh9DQuTYwDG3HYAACS4BOhZHfptgAAAABJRU5ErkJggg==");
}

::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWElEQVQokWP8//8/AymAiSTVDAwMLOgCasYW/xkYGBhh/FtnTxBlA053Ymjg/vcTryYMDV+Z2PHahNPTUJsY0cVxavjKxI6hGJ8GrIoZGBgYGGkecSRrAABJOBMT7b9GFQAAAABJRU5ErkJggg==");
}

::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMElEQVQokWP8//8/AymAiSTVA6dBzdgCp8cwNMAU49LEhE0xPpuY8EliE2ccBvEAAGLfEQE9MAhjAAAAAElFTkSuQmCC");
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOklEQVQokWP8//8/AymAiSTVdNHAAmOoGVvg9MytsycYsdnAiEUthji6k9A1YRiCzQ+MaDSq5DCIBwDz6goZAxDEewAAAABJRU5ErkJggg==");
}

::-webkit-scrollbar-button:horizontal:increment:start {
  display: none;
}

::-webkit-scrollbar-button:horizontal:decrement:end {
  display: none;
}

::-webkit-scrollbar-button:vertical:increment:start {
  display: none;
}

::-webkit-scrollbar-button:vertical:decrement:end {
  display: none;
}

::-webkit-scrollbar-button:active {
  box-shadow: none;
  border: 1.5px solid #a099a1;
}
/*# sourceMappingURL=index.e1bd0a89.css.map */
