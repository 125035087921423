.button {
  @include bt(3px, button);
  appearance: none;
  display: flex;
  align-items: center;
  border: 0;
  padding-left: 6px;
  padding-right: 6px;
  width: 120px;
  height: 36px;
  background: none;
  text-align: center;
  &.underline {
    span {
      @include fl;
    }
  }
  + .button {
    margin-left: 10px;
  }
  > span {
    display: block;
    width: 100%;
    line-height: 22px;
    border: 1px dotted transparent;
    padding: 0 !important;
    @media (min-width: 500px) {
      border-width: 1.5px;
    }
  }
  &[disabled] {
    color: $g3;
    pointer-events: none;
  }
  &:not([disabled]) {
    &:focus {
      @include bt(3px, button-strong);
    }
    &:active {
      @include bt(3px, button-strong-pressed);
    }
    &:focus,
    &:active {
      span {
        border-color: $bl;
        color: $bl;
        background: none;
      }
    }
  }
}
