#windows,
#alerts {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 44px);
  padding-bottom: 44px;
}
.window,
.alert {
  @include bt(3px, window, false);
  position: absolute;
  background: $g2;
  &-inside {
    max-width: calc(100vw - 36px);
    max-height: calc(100vh - 44px - 12px);
    min-width: 300px;
    min-height: 200px;
    overflow: hidden;
    resize: both;
    &::-webkit-resizer {
      display: none;
    }
  }
}

.window-nav {
  background-color: $g3;
}

.alert-nav {
  background-color: $slate;
}

.window-nav,
.alert-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  padding: 0 3px;
  color: white;
  &:before,
  &:after {
    content: unset;
  }
  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 69px);
    margin: 0;
    line-height: 28px;
    &[class*="label"]:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 6px;
      background-size: contain;
    }
    &[class*="label"] span:only-child {
      @include nb;
      width: calc(100% - 24px - 6px);
    }
    &:not([class*="label"]) {
      padding: 0 3px;
    }
  }
  .buttons {
    display: flex;
    label {
      @include bt(3px, button);
      position: relative;
      width: 22px;
      height: 20px;
      background: $g2;
      &[disabled] {
        pointer-events: none;
        &:after {
          opacity: 0.4;
        }
      }
      &:last-child {
        margin-left: 3px;
      }
      &.minimize {
        &:after {
          display: block;
          position: absolute;
          bottom: 4.5px;
          left: 5px;
          width: 10px;
          height: 3px;
          background: $bl;
        }
      }
      &.maximize {
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 12px;
          height: 12px;
          border: 1.5px solid $bl;
          border-top-width: 3px;
          background-color: $g2;
        }
        &:before {
          visibility: hidden;
        }
      }
      &.close {
        &:after {
          content: "";
          background-image: url($x);
          width: 12px;
          height: 10px;
        }
      }
      &.question {
        &:after {
          content: "";
          background-image: url($q);
          width: 9px;
          height: 14px;
        }
      }
      &:not([disabled]):active {
        @include sh(3px, button-reverse);
      }
    }
  }
}

.window-default {
  .window-inside {
    width: 750px;
    height: 470px;
  }
}

.window-content {
  height: 100%;
}

.above-footer {
  height: 100%;
}

#alerts {
  z-index: -1;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#alert-blue-screen {
  position: relative;
  z-index: 10000;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: #00a;
  color: white;
  .content {
    max-width: 610px;
    padding: 20px;
  }
  h3,
  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;
  }
  h3,
  p:last-child {
    text-align: center;
  }
  h3 span {
    display: inline-block;
    padding: 2px 3px;
    background-color: #aaa;
    color: #00a;
  }
  .blink {
    display: inline-block;
    animation: blink 1s cubic-bezier(1, 0, 0, 1) infinite forwards;
  }
}

.alert-inside {
  min-width: unset;
  min-height: unset;
  width: 320px;
  height: 180px;
  resize: none;
}

.alert-content {
  padding: 0 24px;
  height: calc(100% - 28px - 52px);
  p {
    padding: 16px 0 0;
    margin: 0;
  }
}

.alert-footer {
  display: flex;
  padding: 0 24px 16px;
}

#window-welcome {
  .window-inside {
    height: 60vh;
    @media screen and (min-width: 568px) and (max-height: 450px),
      screen and (min-width: 768px) {
      width: 500px;
      height: 250px;
    }
    @media only screen and (min-width: 1000px) {
      width: 700px;
      height: 400px;
    }
  }
  .window-content {
    padding: 20px;
    height: calc(100% - 3px - 40px - 36px);
  }

  .window-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 19px;
    .button {
      @include bt(3px, button-strong);
      &:active {
        @include bt(3px, button-strong-pressed);
      }
    }
  }
  .information-container {
    @include sh(3px, inset);
    width: 100%;
    height: 100%;
    background: $wh;
    padding: 1.5px;
  }
  .information {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 20px;
    line-height: 1.4;
    h3,
    p,
    ul {
      margin: 1em 0 0;
    }
    h3:first-child {
      margin-top: 0;
    }
    ul {
      padding-left: 10px;
      list-style-type: "–";
      li {
        padding-left: 0.5em;
        &:not(:first-child) {
          margin-top: 0.25em;
        }
      }
    }
  }
}

.list-container {
  @include sh(3px, inside);
  background: white;
  height: calc(100% - 28px - 2px);
  margin-top: 2px;
  overflow-y: auto;
  p {
    line-height: 1.4;
    padding: 0 12px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  overflow: auto;
  li {
    position: relative;
  }
  label:not(.label-toggle) {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 3px;
    white-space: nowrap;
    &:not(.folders label) {
      &:after {
        width: 24px;
        height: 24px;
        z-index: 2;
      }
    }
    &:before {
      display: block;
      position: relative;
      z-index: 2;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    &:active,
    &:focus {
      span {
        background: $mauve;
        color: white;
        border: 1px dotted $oak;
        @media (min-width: 500px) {
          border-width: 1.5px;
        }
      }
    }
  }
}

.window-content {
  .list:not(.folders .list) {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 120px);
    row-gap: 24px;
    padding: 9px 0;
    li {
      label {
        white-space: unset;
        text-align: center;
        line-height: 1.3;
        height: 98px;
        &:before {
          margin-bottom: 6px;
          margin-right: 0;
        }
        &:active,
        &:focus {
          span {
            background: $mauve;
            color: white;
            border: 1px dotted $oak;
            @media (min-width: 500px) {
              border-width: 1.5px;
            }
          }
        }
      }
    }
  }
  @each $icon in $icons {
    .label-#{$icon}-small:not(.folders label) {
      @include li;
      &:before {
        background-image: url("#{$url}#{$icon}.png");
      }
    }
  }
}
