$icons: computer, cd, modems, joystick, multimedia, skip-awgs-x2, tryb-sporth,
  wordpad;

@each $icon in $icons {
  .label-#{$icon} {
    &:before {
      background-image: url("#{$url}#{$icon}.png");
      background-repeat: no-repeat;
    }
    &:not(.folders label):not(.label-nested) {
      &:after {
        mask-image: url("#{$url}#{$icon}.png");
      }
    }
  }
  // .label-#{$icon}-small {
  //   &:before {
  //     background-image: url("#{$url}#{$icon}-small.png");
  //     background-repeat: no-repeat;
  //   }
  //   &:not(.folders label):not(.label-nested) {
  //     &:after {
  //       mask-image: url("#{$url}#{$icon}-small.png");
  //     }
  //   }
  // }
}

$menu-icons: taskbar, customize-start-menu, recycle-bin-folder;

@each $icon in $menu-icons {
  .label-#{$icon}:before {
    background-image: url("#{$url}#{$icon}.png");
    background-repeat: no-repeat;
  }
}

.icon {
  display: block;
  width: 40px;
  height: 32px;
  // background-size: 32px 32px;
  background-repeat: no-repeat;
  // background-color: red;
}

.icon-instagram {
  background-image: url("#{$url}instagram.png");
}

.icon-tiktok {
  background-image: url("#{$url}tiktok.png");
}
