@mixin ch($c: $g2, $s: 1px, $b: $wh) {
  background-color: $b;
  background-image: linear-gradient(
      45deg,
      $c 25%,
      transparent 25%,
      transparent 75%,
      $c 75%,
      $c
    ),
    linear-gradient(45deg, $c 25%, transparent 25%, transparent 75%, $c 75%, $c);
  background-size: $s * 2 $s * 2;
  background-position: 0 0, $s $s;
}

@mixin bt($w, $d: default, $c: true) {
  @include sh($w, $d);
  @if $c == true {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: $w * 0.5;
  } @else {
    padding: $w * 2;
  }
  z-index: 4;
  background: $g2;
}

@mixin sh($w, $d: default) {
  @if $d == default {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $wh, inset #{-$w} #{-$w} 0 0 $g3,
      inset $w $w 0 0 $g1;
  } @else if $d == window {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $g1, inset #{-$w} #{-$w} 0 0 $g3,
      inset $w $w 0 0 $wh;
  } @else if $d == button {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $wh, inset #{-$w} #{-$w} 0 0 $g3,
      inset $w $w 0 0 $g2;
    padding-bottom: $w * 0.5;
    padding-right: $w * 0.5;
  } @else if $d == button-reverse {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $wh,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $bl, inset #{-$w} #{-$w} 0 0 $g2,
      inset $w $w 0 0 $g3;
    padding-bottom: $w * 0.5;
    padding-right: $w * 0.5;
  } @else if $d == button-strong {
    box-shadow: inset #{-$w} #{-$w} 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $bl,
      inset #{-$w * 1.5} #{-$w * 1.5} 0 0 $g3, inset $w $w 0 0 $wh;
    padding-bottom: $w * 0.5;
  } @else if $d == button-strong-pressed {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $bl, inset #{-$w} #{-$w} 0 0 $g3,
      inset $w $w 0 0 $g3;
    padding-bottom: $w * 0.5;
  } @else if $d == inside {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $wh,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $g3, inset #{-$w} #{-$w} 0 0 $g2,
      inset $w $w 0 0 $bl;
    padding: $w;
  } @else if $d == inset {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $wh,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $g3;
  } @else if $d == hide {
    box-shadow: inset #{-$w * 0.5} 0 0 0 $bl,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $wh, inset #{-$w} 0 0 0 $g3,
      inset $w $w 0 0 $g1;
  } @else if $d == fieldset {
    box-shadow: inset #{-$w * 0.5} #{-$w * 0.5} 0 0 $wh,
      inset #{$w * 0.5} #{$w * 0.5} 0 0 $g3, inset #{-$w} #{-$w} 0 0 $g3,
      inset $w $w 0 0 $wh;
  }
}

@mixin nb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fl {
  &:first-letter {
    text-decoration: underline;
  }
}

@mixin li {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 116px;
  &:before,
  &:after {
    width: 48px;
    height: 48px;
  }
  &:before {
    margin-bottom: 6px;
  }
  &:after {
    left: 36px;
  }
  span {
    padding: 1px;
    border: 1.5px solid transparent;
  }
}

@mixin ta {
  @include bt(3px, button-reverse);
  @include ch($wh, 1.5px, transparent);
  justify-content: flex-start;
}
